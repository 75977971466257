.root-layout {
    /* margin-top: 56px; */
    min-height: calc(100% - 60px);
    /* margin-top: 64px; */
    padding-top: 60px;
    display: flex;
    /* height: 91vh;
    max-height: 91vh; */
    width: 100%;
    max-width: 100%;
}

.list-layout {
    /* margin-left: 90px; */
    /* background-color: #f0f0f0; */
    background-color: #fff;
    display: flex;
    width: 100%;
    /* width: calc(100% - 75px); */
    /* height: 100%; */
    min-height: 100%;
    /* width: 94vw; */
    /* position: fixed; */
    /* Responsive height without position fixed */
    /* max-height: -webkit-fill-available; */
}

.common-list {
    width: 350px;
    min-width: 350px;
    background-color:  #fff;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition:width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
}

.chat-layout {
    min-width: 70%;
}


/* @media (min-width: 600px) {
    .root-layout {
        margin-top: 64px;
    }
} */

/* @media (max-width: 600px) {
    .common-list {
        width: 0px;
    }
} */


/* START - material-table styles */
.MuiTable-root {
	border-collapse: separate !important;
    /* border-spacing: 0 2px !important; */
}
.material-table {
	overflow-x: auto;
	width: 100%;
}
.material-table table th {
	padding: 5px 10px !important;
	/* border-color: #FFC407; */
	/* border-color: #838383;
	border-style: solid;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 0; */
	border-right-width: 0;
	/* background-color: #FFEAA7; */
	/* background-color: #7c139a21; */
	/* background-color: #e1d7e9; */
	color: #000000;
	font-weight: 600;
	line-height: 1.2rem;
	font-size: 12px;
}
.material-table table th:first-child {
	border-left-width: 1px;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}
.material-table table th:last-child {
	border-right-width: 1.2px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}
.material-table table td {
	padding: 3px 10px;
	font-size: 12px !important;
	height: 40px;
	/* border-color: #838383;
	border-style: solid;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 0;
	border-right-width: 0; */
	/* background-color: #FFF; */
	border-width: 0px 0px 1px 0px !important;
}
.material-table table td:first-child {
	border-left-width: 1px;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	border-width: 0px 0px 1px 1px !important;
}
.material-table table td:first-child h6 {
	font-size: 12px;
	text-align: center;
}
.material-table table td:last-child {
	border-right-width: 1.2px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	border-width: 0px 1px 1px 0px !important;
}
.material-table table tr[mode='update'] td {
	border: 0;
	padding: 10px 10px;
}
.material-table table tr[mode='add'] {
	background-color: transparent;
}

.material-table table tr[mode='add'] td {
	border: 0;
	padding: 10px 10px;
	background-color: #FFF;
}

.material-table table tr[mode='add'] td:last-child {
	background-color: transparent;
}

.material-table table tr[mode='add'] td:nth-last-child(2) {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.material-table .MuiToolbar-root {
	min-height: auto;
	height: 40px;
	margin-bottom: 14px;
}
.material-table .MuiButton-root {
	/* padding-right: 0; */
	height: 32px;
    padding: 0 10px;
    width: auto;
}

.material-table .MuiToolbar-root .MuiIconButton-colorInherit {
	margin-left: 30px;
	padding: 0px;
}

.material-table .MuiAvatar-root {
	width: 25px;
	height: 25px;
	font-size: 12px;
}
.material-table .MuiTableFooter-root td {
	border: 0;
}
.material-table .MuiTableContainer-root {
	overflow: visible;
}
.material-table td > .MuiChip-root {
	border-radius: 4px;
	height: 28px;
	/* width: 66px; */
	width: auto !important;
	background-color: transparent;
	/* color: #000000de; */
}
.material-table td > .MuiChip-root .MuiChip-label {
	padding: 0;
	font-weight: 600;
}

.material-table .MuiTableSortLabel-root {
	position: relative;
	display: block;
	padding-right: 20px;
}
.material-table .MuiTableSortLabel-root .MuiTableSortLabel-icon {
	position: absolute;
	right: 0;
	top: 3px;
}
/* END - material-table styles */

.material-table .MuiPaper-elevation2 {
	box-shadow: none;
	/* padding: 2rem; */
	background: transparent;
	border-radius: 8px;
	/* border: 1px solid #BCBCBD; */
}

/* @media (min-width: 600px) {
    .root-layout {
        margin-top: 64px;
    }
} */

/* @media (max-width: 600px) {
    .common-list {
        width: 0px;
    }
} */

.material-table table tr {
	background-color: #FFF;
}

.material-table table thead tr {
	background: transparent;
}

.material-table .MuiTableRow-footer {
	background: transparent;
}

.material-table .MuiTableRow-footer td {
	height: auto;
}

.material-table .MuiTableCell-root {
	/* border-bottom: none; */
	border: 1px solid #f0f2f5;
}

.material-table thead th {
	/* border: none !important; */
}

.material-table  .custom-headers th {
	border-width: 1px !important;
	border-bottom: none;
	border-color: #FFF !important;
	border: 1px solid #F0F2F5 !important;
}

.material-table .MuiTableCell-root>div>button.MuiIconButton-root {
	padding: 8px;
}

/* MUI data table styles */
.data-table .MuiPaper-elevation4 {
	box-shadow: none;
	background: none;
}

.data-table table th {
	z-index: 0;
}

.data-table table td {
	height: 34px;
}

/* Custom perfomance table template */
.custom-table {
	min-height: 300px;
	/* max-width: 500px; */
    background: linear-gradient(0deg, #2753b5, #751c21);
	color: #FFF;
	padding: 25px;
	font-weight: 600;
}

.custom-table table {
	border-spacing: 0 15px;
	border-collapse: separate;
	margin-top: 10px;
}

.custom-table tbody td {
	background-color: #FFF;
}

.custom-table tbody td, .custom-table thead th {
	text-align: left;
	padding: 8px 15px;
}

.custom-table table td:first-child {
	/* border-left-width: 1px; */
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	/* border-width: 0px 0px 1px 1px !important; */
}
.custom-table table td:last-child {
	/* border-right-width: 1.2px; */
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	/* border-width: 0px 1px 1px 0px !important; */
}

/* match template download custom table styles */

.match-custom-table {
	height: 100%;
    /* background: linear-gradient(50deg, #091038, #11266a); */
	color: #FFF;
	padding: 25px;
	min-width: 450px;
	font-weight: 600;
}

.match-custom-table table {
	width: 100%;
	border-spacing: 0 15px;
	border-collapse: separate;
	/* margin-top: 10px; */
}

.match-custom-table tbody td {
	background-color: #FFF;
	color: #000;
}

.match-custom-table tbody td, .match-custom-table thead th {
	font-size: 13px;
	text-align: left;
	padding: 2px 7px;
}

.match-custom-table table td:first-child {
	white-space: nowrap;
	/* border-left-width: 1px; */
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	/* border-width: 0px 0px 1px 1px !important; */
}
.match-custom-table table td:last-child {
	/* border-right-width: 1.2px; */
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	/* border-width: 0px 1px 1px 0px !important; */
}

