:root {
    --cell-height: 60px;
    --scale-width: 80px;
    --calendar-max-height : 460px; /* 100% */
}

.weekCalendar {
    padding-top: var(--cell-height) !important;
    padding-left: var(--scale-width) !important;
    color: #535353 !important;
}

.weekCalendar__header {
    height: var(--cell-height) !important;
    line-height: normal;
    padding-left: var(--scale-width) !important;
}

.weekCalendar__scaleHeader {
    height: var(--cell-height) !important;
    line-height: var(--cell-height) !important;
    /* background: none !important; */
    background-color: #FFF !important;
    border: none !important;
    width: var(--scale-width) !important;;
}

.weekCalendar__scaleColumn {
    padding-top: var(--cell-height) !important;
    border: none !important;
    width: var(--scale-width) !important;
}

.weekCalendar__scaleCell {
    border-bottom: none !important;
    border-right: 1px solid #b7b7b7 !important;
    line-height: normal !important;
}

.weekCalendar__scaleCell:nth-child(even) > span {
    visibility: hidden;
}

.calendarBody__column {
    min-width: auto !important;
    
}

.calendarBody__column > .calendarBody__cell:nth-child(odd) {
    border-bottom: none;
}

.weekCalendar__headerWrapper {
    border-top: none !important;
    background: none !important;
    color: inherit !important;
}

.weekCalendar__headerColumn {
    border-right: none !important;
    padding-left: 10px;
}


.weekCalendar__content {
    max-height: var(--calendar-max-height) !important;
  }

/* .calendar_default_event_inner {
    background: #2e78d6;
    color: #fff;
    border: none;
    font-size: 10pt;
    padding: 12px;
}
.calendar_default_main {
    border: none;
    font-family: 'Roboto';
}

.calendar_default_colheader_inner {
    background: none;
    border-right: none;
}

.calendar_default_corner_inner {
    background: none;
    border: none;
}

.calendar_default_corner div[unselectable=on]{
    display: none;
}

.calendar_default_cornerright_inner {
    background: none;
}

.calendar_default_rowheader_inner {
    background: none;
    border-bottom: none;
}

.calendar_default_scroll {
    background: none;
}

.calendar_default_now {
    z-index: 1;
} */