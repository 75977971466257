
/* START - livescore-table styles */
.MuiTable-root {
	border-collapse: separate !important;
	/* border-collapse: collapse !important; */
    /* border-spacing: 0 2px !important; */
}
.livescore-table {
	overflow-x: auto;
	width: 100%;
}
.livescore-table table th {
	padding: 5px 10px !important;
	border: 0.5px solid #00000069 !important;
	/* border-color: #838383;
	border-style: solid;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 0; */
	border-right-width: 0;
	/* background-color: #FFEAA7; */
	/* background-color: #7c139a21; */
	/* background-color: #e1d7e9; */
	/* color: #000000; */
	font-weight: 600;
	line-height: 1.2rem;
	font-size: 12px;
	color: #333;
	text-align: center;


}
.livescore-table table th:first-child {
	border-left-width: 1px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;

}
.livescore-table table th:last-child {
	border-right-width: 1.2px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
.livescore-table table td {
	padding: 3px 10px;
	font-size: 12px !important;
	/* height: 40px; */
	/* border-color: #838383;
	border-style: solid;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 0;
	border-right-width: 0; */
	/* background-color: #FFF; */
	border-width: 0px 0px 1px 0px !important;
	color: #333333;
	text-align: center;
	/* border: 0.5px solid #00000069 !important; */
}
.livescore-table table td:first-child {
	border-left-width: 1px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-width: 0px 0px 1px 1px !important;
}
.livescore-table table td:first-child h6 {
	font-size: 12px;
	text-align: center;
}
.livescore-table table td:last-child {
	border-right-width: 1.2px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	border-width: 0px 1px 1px 0px !important;
}
.livescore-table table tr[mode='update'] td {
	border: 0;
	padding: 10px 10px;
}
.livescore-table table tr[mode='add'] {
	background-color: transparent;
}

.livescore-table table tr[mode='add'] td {
	border: 0;
	padding: 10px 10px;
	background-color: #FFF;
}

.livescore-table table tr[mode='add'] td:last-child {
	background-color: transparent;
}

.livescore-table table tr[mode='add'] td:nth-last-child(2) {
	/* border-top-right-radius: 0px;
	border-bottom-right-radius: 0px; */
}

.livescore-table .MuiToolbar-root {
	min-height: auto;
	height: 40px;
	margin-bottom: 14px;
}
.livescore-table .MuiButton-root {
	/* padding-right: 0; */
	height: 32px;
    padding: 0 10px;
    width: auto;
}

.livescore-table .MuiToolbar-root .MuiIconButton-colorInherit {
	margin-left: 30px;
	padding: 0px;
}

.livescore-table .MuiAvatar-root {
	width: 25px;
	height: 25px;
	font-size: 12px;
}
.livescore-table .MuiTableFooter-root td {
	border: 0;
}
.livescore-table .MuiTableContainer-root {
	overflow: visible;
}
.livescore-table td > .MuiChip-root {
	border-radius: 4px;
	height: 28px;
	/* width: 66px; */
	width: auto !important;
	background-color: transparent;
	/* color: #000000de; */
}
.livescore-table td > .MuiChip-root .MuiChip-label {
	padding: 0;
	font-weight: 600;
}

.livescore-table .MuiTableSortLabel-root {
	position: relative;
	display: block;
	padding-right: 20px;
}
.livescore-table .MuiTableSortLabel-root .MuiTableSortLabel-icon {
	position: absolute;
	right: 0;
	top: 3px;
}
/* END - livescore-table styles */

.livescore-table .MuiPaper-elevation2 {
	box-shadow: none;
	/* padding: 2rem; */
	background: transparent;
	/* border-radius: 8px; */
	/* border: 1px solid #BCBCBD; */
}

/* @media (min-width: 600px) {
    .root-layout {
        margin-top: 64px;
    }
} */

/* @media (max-width: 600px) {
    .common-list {
        width: 0px;
    }
} */

.livescore-table table tr {
	background-color: #FFF;
}

.livescore-table table thead tr {
	background: transparent;
}

.livescore-table .MuiTableRow-footer {
	background: transparent;
}

.livescore-table .MuiTableRow-footer td {
	height: auto;
}

/* .livescore-table .MuiTableRow-root.Mui-selected {
	background-color:#d6e9f3;
}

.livescore-table .MuiTableRow-root.Mui-selected:hover {
	background-color:#d6e9f3;
} */

.livescore-table .MuiTableCell-root {
	/* border-bottom: none; */
	border: 1px solid #f0f2f5;
}

.livescore-table thead th {
	border: none !important;
}

.livescore-table  .custom-headers th {
	border-width: 1px !important;
	border-bottom: none;
	border-color: #FFF !important;
	border: 1px solid #F0F2F5 !important;
}

.livescore-table .MuiTableCell-root>div>button.MuiIconButton-root {
	padding: 8px;
}

/* MUI data table styles */
.data-table .MuiPaper-elevation4 {
	box-shadow: none;
	background: none;
}

.data-table table th {
	z-index: 0;
}

.data-table table td {
	height: 34px;
}

/* Table title */
.table-title {
	font-size: 16px;
    padding: 10px 10px 10px 20p	x;
    /* color: rgba(0, 0, 0); */
    margin: 5px 0;
	/* background-color: #dfe1e4; */
	/* text-transform: uppercase; */
	font-weight: 600;
	/* border: 1px solid #00000069; */
	border-bottom: none;
}

/* Decimal view switch */
.decimal-switch-root {
	display: flex;
	justify-content: space-between;
}
.decimal-switch-root p {
	margin: 0;
}

/* Table Footer */

.table-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    padding: 14px 10px 14px 20px;
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
	background-color: #fafafa;
	text-transform: uppercase;
}