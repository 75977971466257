body {
  margin: 0;
  font-family: 'Poppins', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: 'Poppins' !important
}

textarea {
  font-family: 'Poppins' !important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera input up&down carets*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  color: #232F3BCC;
  /* font-family: Roboto; */
}

textarea::-webkit-input-placeholder {
  color: #0000004d;
}

textarea:-moz-placeholder { /* Firefox 18- */
  color: #0000004d;  
}

textarea::-moz-placeholder {  /* Firefox 19+ */
  color: #0000004d;  
}

textarea:-ms-input-placeholder {
  color: #0000004d;  
}

textarea::placeholder {
  color: #0000004d;  
}

#arena-background-post-text-field::placeholder {
  color: inherit;
  opacity: 0.6;
}

#arena-background-post-text-field::-moz-placeholder {  /* Firefox 19+ */
  color: inherit; 
  opacity: 0.6;
}

.closeSlide {
  margin-right:0;
  width:0px;
  padding-left:0;
  padding-right:0;
  transition: 0.2s all ease-out;
  /*Just reverse any spacing styling you've applied to make it **magically** disappear*/
}