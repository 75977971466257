#header {
    /* color: #ffffff;
    height: 60px;
    background-color: #333333;
    padding: 0 14px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    min-width: 400px !important; */

    color: #ffffff;
    height: 60px;
    background-color: #333333;
    background: transparent;
    padding: 0 14px 0 0;
    position: absolute;
    top: 60px;
    left: 75px;
    width: auto;
    z-index: 9999;
    /* min-width: 400px !important; */
    box-shadow: none;
  }
  
  #meeting-title {
    text-align: center;
  }

  #meeting-title .title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .info-icon {
    position: absolute !important;
    right: 46px;
    padding: 0px !important
  }

  .info-icon svg path {
    fill: #FFF;
  }

  button{
    outline: none !important;
    border: none !important;
  }
  .toolbar{
    padding: 5px !important;
  }
  .meetingName
  {
    margin-right: 20px;
  }
  
  .headerLight{
    color: #706969 !important;
    background-color: #eeeeee !important;
  }
  
  #navSessionInfo {
    position: absolute;
  }

  #navButtons {
    position: absolute;
    left: 0;
    right: 0;

  }
  #navLeaveButton
  {
    /* position: absolute; */
    /* right: 40px; */
    background-color: #E30613;
    color: white;
    /* transform: scale(0.75); */
    /* top: 0; */

    /* New Styles */
    border-radius: 6px;
    margin-left: 10px;
  }
  #navLeaveButton #leftspace{
    margin-left: 10px;
  }
  #fullScreenButton
  {
    /* position: absolute;
    right: 140px;
    top: 3px; */
    margin-right: 10px;
    margin-left: 10px;

    /* New Styles */
    background-color: #ffffffb3;
    border-radius: 6px;
  }
  #navScreenButton
  {
    /* position: absolute;
    right: 170px;
    top: 0; */
    margin-right: 10px;
    margin-left: 10px;

    /* New Styles */
    background-color: #ffffffb3;
    border-radius: 6px;
  }
  #navCamButton{
    /* position: absolute;
    right: 210px;
    top: 0; */
    margin-right: 10px;
    margin-left: 10px;

    /* New Styles */
    background-color: #ffffffb3;
    border-radius: 6px;
  }
  #navMicButton{
    /* position: absolute;
    right: 240px;
    top: 0; */
    margin-right: 10px;
    /* margin-left: 10px; */

    /* New Styles */
    background-color: #ffffffb3;
    border-radius: 6px;
  }
  #navAudioButton
  {
    /* position: absolute;
    right: 270px;
    top: 0; */
    margin-right: 10px;
    margin-left: 10px; 

    /* New Styles */
    background-color: #ffffffb3;
    border-radius: 6px;
  }
  
  #titleContent{
    max-width: 100px;
    background-color: #494949;
    margin: 5px -18px;
    padding: 0px 15px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    float: right;
    margin-top: 20px;
  }
  
  .titleContentLight{
    background-color: #dfdfdf !important; 
    color: #000;
  }
  
  #header_img {
    max-width: 135px;
    margin-right: 10px;
    margin-top: 10px;
  }

  #session-title{
    font-family: 'Open Sans', sans-serif; 
  }


#point{
  width: 10px;
  height: 10px;
  position: absolute;
  top: 12px;
  right: 33px;
  border-radius: 50%;
  background-color: #ffa600;
  border: 1px solid #000;
  z-index: 99999;
}
.pointLight{
  border: 1px solid #ffffff !important;
}


@media only screen and (max-width: 700px) {
  #titleContent, #navChatButton {
      display: none;
  }
}
